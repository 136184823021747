/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('recover', {
      parent: 'public',
      url: '',
      abstract: true,
      templateUrl: 'sections/recover/templates/recover.ng.html',
      controller: 'acpSectionRecoverCtrl',
      data: {
        permissions: {
          only: 'password_unauthed',
          redirectTo: {
            password_unauthed: 'dashboard'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.layout" */ 'sections/recover').then(
            $ocLazyLoad.loadModule
          );
        },
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('sections/recover', 'page-title')
          );
        },
        $hideAppBarLogo: /* @ngInject */ function(acpFrontendConfigModel) {
          return acpFrontendConfigModel.get(
            acpFrontendConfigModel.keys.HIDE_APPBAR_LOGO,
            false
          );
        }
      }
    })
    .state('recover.password', {
      url: '/recover/password',
      template: '<acp-recover-password></acp-recover-password>',
      resolve: {
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/recover',
              'page-title-recover.password'
            )
          );
        }
      }
    })
    .state('recover.username', {
      url: '/recover/username',
      template: '<acp-recover-username></acp-recover-username>',
      resolve: {
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/recover',
              'page-title-recover.username'
            )
          );
        }
      }
    });
}

export default states;
